import React, { Component } from "react"

export default class ContactForm extends Component {
  componentDidMount() {
    const script = document.createElement('script');

    script.src = 'https://azaleakitchen.activehosted.com/f/embed.php?id=3';
    script.async = true;

    document.body.appendChild(script);
  }

  render() {
    return (
      <div className="_form_3" />
    )
  }
}
