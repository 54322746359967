import React from "react"
import { graphql } from "gatsby"
import { getSrc } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { PageHeader, BannerCenter } from "../utils"
import ContactForm from "../components/globals/ContactForm"

const CateringPage = ({ data }) => {
  const smImg = getSrc(data.smImg)
  const img = getSrc(data.img)

  return (
    <Layout>
      <Seo
        title="Catering & Events"
        keywords={[`Catering in Asheville and Biltmore`]}
      />
      <PageHeader smImg={smImg} img={img}>
        <BannerCenter
          headerProps={{ style: { textAlign: `left` } }}
          title="Make your event delicious."
          titleProps={{
            style: {
              fontSize: `clamp(1.25rem, 3.5rem, 14vw)`,
            },
          }}
          subtitle="With room to accommodate up to 100 people, our patio and dining room are perfect for your next event."
        ></BannerCenter>
      </PageHeader>
      <br />
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 960,
          padding: `1.45rem 1.0875rem`,
        }}
      >
        <h1
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "10vh",
            margin: 0,
          }}
        >
          Get a Quote.
        </h1>
      </div>
      <ContactForm></ContactForm>
      <br />
      <br />
    </Layout>
  )
}

export default CateringPage

export const query = graphql`
  query {
    smImg: file(
      relativePath: { eq: "fall-2024/abk-patio-evening-ambience-p-1.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          formats: [AVIF, WEBP]
          placeholder: BLURRED
          quality: 60
          transformOptions: { cropFocus: ENTROPY, fit: COVER }
          width: 992
        )
      }
    }
    img: file(relativePath: { eq: "fall-2024/abk-patio-afternoon-l-2.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          formats: [AVIF, WEBP]
          placeholder: BLURRED
          quality: 30
          transformOptions: { cropFocus: ENTROPY, fit: COVER }
          width: 1800
        )
      }
    }
  }
`
